import { useEffect } from 'react';
import ChatBot from '../../components/Chatbot.js';
import setAnalyticsData from '../../utils/adobe.js';
import SupportEmailLink from '../../components/SupportEmail.js';

function Error() {
  const supportEmailSubject = "SF Ting Participant Agreement"
  

  useEffect(() => {
    // Load 1x Script
    var oneXScript = document.createElement("script");
    oneXScript.src = process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js";
    document.body.appendChild(oneXScript);

    setAnalyticsData({
      screenId: 'ting-pa-error',
      screenChannel: 'ting',
    })
  }, []);

  return (
    <div className="main-container">
      <header data-type="1x-header-auth"></header>

      <main className="main">
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-12">
              <h1 className="-oneX-heading-h1">We are currently experiencing technical difficulties. Please logout and click on the link in your email to try again at a later time. </h1>
              <h1 className="-oneX-heading-h1">If you continue to encounter issues, please contact the <SupportEmailLink subject={supportEmailSubject}/> for assistance.</h1>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>

      <footer data-type="1x-footer"></footer>
    </div>
  )
}

export default Error;