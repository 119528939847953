import { React, useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import EnrollmentForm from '../components/EnrollmentForm.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserEligibility } from '../api/getUserEligibility.js';
import logger from '../utils/logger.js';
import { removeResidences } from '../utils/residences.js';
import { refreshSession, refreshOktaToken, isOktaTokenAvailable, isSSOTokenAvailable, oktaTokenExpiresBefore, removeAuthCookies, getOktaToken, getSSOToken } from '../utils/session-management.js';

function Home() {
  const sfCustomerId = useRef("");
  const cmpid = useRef("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  // Periodically attempt to refresh the Okta token
  const monitorOkta = () => (setInterval(() => {
    let otkaTokenExpiresSoon = oktaTokenExpiresBefore(process.env.REACT_APP_OKTA_TOKEN_EXPIRATION_BUFFER_SECONDS)
    if (otkaTokenExpiresSoon) {
      refreshOktaToken()
    }
  }, process.env.REACT_APP_OKTA_TOKEN_REFRESH_INTERVAL_SECONDS * 1000))

  useEffect(() => {
    if (typeof dataLayer === "undefined") {
      window.dataLayer = {
        adobe_link_name: "",
        adobe_button_name: "",
        adobe_radio_name: "",
        adobe_checkbox_name: ""
      }
    }

    window.screenChange = true; // See https://datt.sfgitlab.opr.statefarm.org/web/job-aids/?doc=spas.react
    window.dataLayer = window.dataLayer || {};

    // Load 1x Script
    var oneXScript = document.createElement("script");
    oneXScript.src = process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js";
    document.body.appendChild(oneXScript);

    // Begin monitoring Okta token
    monitorOkta()

    const fetchAssets = async () => {
      try {
        sfCustomerId.current = Cookies.get('SFcustId') != null ? Cookies.get('SFcustId') : "";

        const extcmpid = query.get('cmpid') ? "cmpid=" + query.get('cmpid') : "";
        const intcmpid = query.get('intcmpid') ? "intcmpid=" + query.get('intcmpid') : "";

        if (extcmpid !== "" && intcmpid !== "") {
          cmpid.current = "?" + extcmpid + "&" + intcmpid;
        } else if(extcmpid !== "") {
          cmpid.current = "?" + extcmpid;
        } else if(intcmpid !== "") {
          cmpid.current = "?" + intcmpid;
        }

        // Redirect to proofing/login page if Okta token or SSO token are not available
        if (isOktaTokenAvailable() || isSSOTokenAvailable()) {
          // Validate session. If the session is not considered valid,
          // clear the relevant Authentication cookies from their browser
          // and redirect the user to the Proofing page so they are given the
          // opportunity to log in and start the flow again
          let isValidSession = await refreshSession()
          if (!isValidSession) {
            removeAuthCookies()
            const proofingURL = process.env.REACT_APP_AUTH_URL + "?goto=" + encodeURIComponent(process.env.REACT_APP_DOMAIN + cmpid.current)
            window.location.href = proofingURL
          }

          // Once we have a "good" Okta Token or SSO Token, run IOT Eligibility API
          // Fetch data from IoT eligibility API
          const res = await getUserEligibility(cmpid.current)
          const responseStatus = await res.status;

          if (responseStatus == 200) {
            const responseData = await res.json();
            logger('Home getUserEligibility',
              {
                oktaToken: getOktaToken(),
                ssoToken: getSSOToken(),
                sfCustomerId: sfCustomerId.current,
                extcmpid: extcmpid,
                intcmpid: intcmpid,
                cmpid: cmpid.current,
                responseData
              }
            )

            // Remove any WA eligible residences
            responseData.eligibleResidences = removeResidences(responseData.eligibleResidences, 'WA')
            // Pass data to the page via props
            // Redirect to ineligible page if customer has no eligible residences
            if (responseData.eligibleResidences == null || responseData.eligibleResidences.length == 0) {
              logger('Home user is ineligible')
              navigate('/ineligible', { state: responseData });
            } else {
              // Customer is eligible, so pass the responseData to the page and load the enrollment form
              setData(responseData)
              setIsLoading(false)
            }
          } else if (responseStatus == 401 || responseStatus == 403) {
            // Redirect to proofing
            // logger('Home getUserEligibility 401/403 redirecting to proofing')
            const proofingURL = process.env.REACT_APP_AUTH_URL + "?goto=" + encodeURIComponent(process.env.REACT_APP_DOMAIN + cmpid.current)
            window.location.href = proofingURL
          } else if (responseStatus === 409 ) {
            // logger('Home getUserEligibility 409 redirecting to /error/technical due to ', responseStatus)
            navigate('/error/technical?err=UserEligible&cd=exc');
          } else {
            if (await res.timedOut) {
              // logger('Home getUserEligibility timedOut redirecting to /error/technical due to ', responseStatus + cmpid.current)
              navigate('/error/technical?err=UserEligible&cd=' + responseStatus + cmpid.current);
            } else {
              // logger('Home getUserEligibility error redirecting to /error due to ', responseStatus + cmpid.current)
              navigate('/error/' + responseStatus + cmpid.current)
            }
          }
        } else {
          // Redirect to proofing
          const proofingURL = process.env.REACT_APP_AUTH_URL + "?goto=" + encodeURIComponent(process.env.REACT_APP_DOMAIN + cmpid.current);
          window.location.href = proofingURL
        }
      } catch(ex) {
        //logger('Home exception redirecting to /error/technical due to ', ex)
        navigate('/error/technical?err=UserEligible&cd=exc');
      }
    }

    fetchAssets()
  }, []);

  return (
    <div className="main-container">
      <head>
        <title>Ting Enrollment</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossOrigin="anonymous"/>
        <link rel="stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/lib/normalize.css"}/>
        <link rel="stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/1x.core.css"} type="text/css"/>

        <meta name="description" content="Enroll for a Ting Device."/>
      </head>

      <header data-type="1x-header-auth"></header>

      <div id="keepalive">
        <img src={process.env.REACT_APP_OAMS_KEEPALIVE_URL} alt="" className="displayBlock"/>
      </div>

      <div id="loadSpinner" style={{backgroundColor: 'rgba(241,241,241,0.73)', width: '100%', display: 'none', height: '100%', zIndex: '3000', top: '0', position: 'fixed'}}>
        <div className="spinner-border" style={{width: '10rem', height: '10rem', backgroundColor: 'transparent', left: '50%', marginLeft: '-5rem', zIndex: '10', top: '50%', marginTop: '-5rem', position: 'fixed'}} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>

      {isLoading ? (
        <div id="spinner-container" style={{backgroundColor: 'rgba(241,241,241,0.73)', width: '100%', height: '100%', zIndex: '3000', top: '0', position: 'fixed'}}>
          <div className="spinner-border" style={{width: '10rem', height: '10rem', backgroundColor: 'transparent', left: '50%', marginLeft: '-5rem', zIndex: '10', top: '50%', marginTop: '-5rem', position: 'fixed'}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
      <div className="main">
        <div className="-oneX-container">
          <div className="-oneX-row">
            <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2 pb-5">
              <h1>Ting Enrollment</h1>
            </div>
            <div className="-oneX-col-12 pb-5">
              <div id="enrollmentFormDiv">
                <EnrollmentForm data={data}/>
              </div>

            </div>
            <div className="-oneX-col-12 pt-5" style={{color: 'gray'}}>
              <strong>DISCLAIMERS</strong>
              <ol>
                <li>To be eligible for this offer, you must have a State Farm non-tenant homeowners, condominium unit owners, manufactured home, or farm and ranch policy from State Farm Fire & Casualty Company, State Farm General Insurance Company, State Farm Lloyds or State Farm Florida Insurance Company.  Limited supplies in participating states.</li>
                <li>Ting detects arcing signals caused by damaged wires, loose connections, or faulty devices that are often precursors to electrical fires. Our Ting Support Team will notify you through a phone call, text, email, and/or App notification if Ting identifies a hazard. If needed, a Ting-certified, licensed electrician will be dispatched to help find and fix the hazard, with your $1,000 credit at the ready. <a href="https://www.tingfire.com/ting-terms-of-service/" target="_blank" rel="noopener noreferrer"  className="-oneX-link--inline">See Ting Terms of Service for details.</a></li>
              </ol>
              <p>State Farm, the State Farm Logo, and Like a good neighbor, State Farm is there are registered trademarks of State Farm Mutual Automobile Insurance Company.</p>
            </div>
          </div>
        </div>
      </div>
      )}

      <footer data-type="1x-footer"></footer>

      <script rel="preload" src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
      <script rel="preload" src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
      <script src={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js"}></script>

    </div>
  );
}

export default Home;