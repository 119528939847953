/*
Fetches the data from IOT Eligibility API
*/

import sleeper from '../utils/sleep-promise.js';
import logger from '../utils/logger.js';
import { refreshOktaToken, getOktaToken, getSSOToken } from '../utils/session-management.js';

export async function getUserEligibility(cmpid, tries = 1) {
  let headers = {}
  headers['Content-Type'] = 'application/json'
  headers['Cache-Control'] = 'private, max-age=0, must-revalidate'

  // Prioritize Okta, but use SSO as fallback
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    let oktaToken = getOktaToken()
    headers['Authorization'] = oktaToken !== undefined && oktaToken.length > 0 ? `Bearer ${oktaToken}` : ""
  } else { // Default to SSO if Okta is "not good/available", at least until SSO is sunset
    let ssoToken = getSSOToken()
    headers['X-SF_SSO_TOKEN'] = ssoToken
  }

  try {
    const res = await fetch(process.env.REACT_APP_ELIGIBILITY_URL+'',
    {
      headers: headers,
      method: 'GET'
    }
    ).catch(error => {
      logger("getUserEligibility fetch error", error)
      res.status(500).json({response: "Something Went Wrong."})
      res.end()
    })

    if (res.status === 409) {
      res.badResponse = true
      return res
    } else if (res.status !== 200 && res.status !== 401 && res.status !== 403) {
      if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
        // Wait time to try again gets incrementally longer
        await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL);
        // Recursively call getUserEligibility() up until REACT_APP_MAX_ENROLLMENT_TRIES when there's an error
        logger(`getUserEligibility 5xx error retry #${tries}`)
        return getUserEligibility(cmpid, tries + 1)
      }
      res.timedOut = true
    }
    return res
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL);
      // Recursively call getUserEligibility() up until REACT_APP_MAX_ENROLLMENT_TRIES when there's an error
      logger(`getUserEligibility error retry #${tries}`)
      return getUserEligibility(cmpid, tries + 1)
    }
    return {
      timedOut: true
    }
  }
}