import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FullScreenSpinner from '../components/media/FullScreenSpinner';
import TermsAndConditionsDefault from './TermsAndConditionsDefault';
import TingDisclaimer from './TingDisclaimer';
import Chatbot from './Chatbot';
import SFTingLogo from '../components/media/SFTingLogo.js';
import { accept } from '../api/accept.js';
import logger from '../utils/logger.js';
import CustomButton from './CustomButton.js';

function AcceptanceForm({ data }) {
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    console.log("AcceptanceForm data: ", data)

    setLoading(false)
    console.log(loading)
  }, []);

  const termsandConditionsRef = useRef(null);

  useLayoutEffect(() => {
    const current = termsandConditionsRef.current;
    window.oneX.addElement(current);

    return () => {
      window.oneX.removeElement(current);
    }
  },[])



  const confirmReadAgreement = () => {
    var checkboxConfirmReadAgreement = document.getElementById("checkboxConfirmReadAgreement");
    var acceptButton = document.getElementById("acceptButton");
    if (checkboxConfirmReadAgreement.checked) {
      acceptButton.disabled = false;
    } else {
      acceptButton.disabled = true;
    }
  }

  const acceptAgreement = async (event) => {
    event.preventDefault() // Prevents the <form> from reloading the page when `onSubmit` is triggered
    setLoading(true)
    // Send request to /pa/acceptance API here
    logger("PA2 AgreementForm acceptAgreement() accept initiated", {data});

    const acceptResp = await accept(data);
    const acceptRespStatus = await acceptResp.status;
    if (acceptRespStatus === 200) {
      logger("PA2 AgreementForm acceptAgreement() accept successful", {data, acceptRespStatus});
      navigate('/pa/success')
    } else {
      let respJson = null;
      const contentType = acceptResp.headers?.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        respJson = await acceptResp.json();
      }
      logger("PA2 AgreementForm acceptAgreement() accept failed", {
        data,
        acceptRespStatus,
        acceptRespBody: respJson,
      });
      navigate('/pa/error')
    }
    console.log("accepted agreement!")
  }

  return (
  <div ref={termsandConditionsRef}>
    <div>
      {loading ? (<FullScreenSpinner/>) : (<p style={{'display': 'none'}}></p>)}
      <div className="-oneX-container">
        <form id="acceptanceForm" onSubmit={acceptAgreement}>
          <div className="-oneX-row">
            <div className="-oneX-col-9 pt-5">
              <h1 className="-oneX-heading-h1" style={{color: '#D62311'}}>State Farm&reg; and Ting want to continue to help protect your family from electrical fires.</h1>
            </div>
            <div className="-oneX-col-2">
              <SFTingLogo/>
            </div>
          </div>
          <div className="-oneX-row">
            <div className="-oneX-col-11">
              <p className="-oneX-body--intro-sm">The Ting Participant Agreement has been updated and accepting the new program terms is required to continue your free monitoring service.</p>
                <p className="-oneX-body--intro-sm">Some of the changes to the participant agreement include:</p>
                <ul>
                  <li className="-oneX-body--intro-sm">Removing the three-year participation restriction</li>
                  <li className="-oneX-body--intro-sm">Sharing information about electrical fire loss (if any) with Whisker Labs</li>
                  <li className="-oneX-body--intro-sm">Allowing State Farm to use Ting data for related uses like rating, research, and claims</li>
                </ul>
              <p className="-oneX-body--intro-sm">Please read and indicate your acceptance below.</p>
            </div>
          </div>
          <div className="-oneX-row">
            <div className="-oneX-col-12 -oneX-col-md-10 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-offset-lg-2">
              <h2 className="-oneX-heading-h2 pt-3">Ting Enrollments</h2>

              <h3 className="-oneX-heading-h3 pt-3">Name</h3>
              <div className="pt-3">
                <p className="-oneX-body--intro-sm">{data?.firstName + ' ' + data?.lastName}</p>
              </div>

              <h3 className="-oneX-heading-h3 pt-3">Enrolled {data?.residences?.length > 1 ? "Addresses" : "Address"}</h3>
              <div className="pt-3">
                <ul>
                  {data?.residences?.map((residence, index) => (
                    <li className="-oneX-body--intro-sm" key={index}>{residence.addressLine1 + (residence.addressLine2 ? ' ' + residence.addressLine2 : '') + ', ' + residence.city + ', ' + residence.state + ' ' + residence.zip}</li>
                  ))}
                </ul>
              </div>

              <div className="-oneX-row pt-3 pt-md-5">
                <div className="-oneX-col-12 text-center pt-3">
                  <h3 className="-oneX-heading-h3 pt-3">Program Terms</h3>
                </div>
                <div className="-oneX-col-12">
                  <div className="-oneX-terms-and-conditions pt-3" id="terms-and-conditions">
                    <TermsAndConditionsDefault/>
                    <div className="-oneX-terms-and-conditions-checkbox">
                      <input id="checkboxConfirmReadAgreement" type="checkbox" className="-oneX-checkbox" defaultChecked={false} onClick={confirmReadAgreement.bind(this)}/>
                      <label htmlFor="checkboxConfirmReadAgreement">I have read, understand, and agree with the program terms above.</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="-oneX-row">
                <div className="-oneX-col-12 d-flex justify-content-center pt-3">
                  <CustomButton id="acceptButton" type="submit" disabled={true}>Accept</CustomButton>
                </div>
              </div>

              <TingDisclaimer/>
            </div>
          </div>
        </form>
      </div>
      <Chatbot/>
    </div>
  </div>
  )
}

export default AcceptanceForm;